import { DoorType, MountType, SystemType } from "@/app/@types/shared";
import { Partition } from "../domain";
import { MountHardware } from "../domain/entities/hardware";
import { MountHardwareStrategyRegistry } from "./mount-hardwares-strategies.service";
import { useDictStore } from "@/stores/dictStore";

type TopTrack = {
    structureCode: string;
    qty: number;
};

type CreateMountsHardwaresArgs = {
    installedSystemCode: string;
    activeDoorStructureType: string;
    activeMountStructureType: string;
    partition: Partition;
};

export class MountsService {
    static createMountsHardwares({
        installedSystemCode,
        activeDoorStructureType,
        activeMountStructureType,
        partition,
    }: CreateMountsHardwaresArgs): MountHardware[] {
        if (!partition) {
            return [];
        }

        const mountsStrategiesRegiest = new MountHardwareStrategyRegistry();

        const Strategy = mountsStrategiesRegiest.getStrategy(
            installedSystemCode as SystemType,
            activeDoorStructureType as DoorType,
            activeMountStructureType as MountType,
        );

        if (!Strategy) {
            return [];
        }

        const strategy = new Strategy(partition);
        return strategy.create() as MountHardware[];
    }

    static getTotalWidth(partition: Partition): number {
        return partition.dimensions.width.getLength().toFt().getValue();
    }

    static getTotalHeight(partition: Partition): number {
        return partition.dimensions.height.getLength().toFt().getValue();
    }

    static getDoorTotalWidth(partition: Partition): number {
        const door = partition.getActiveDoor();

        return door.dimensions.width.getLength().toFt().getValue();
    }

    static getDoorTotalHeight(partition: Partition): number {
        const door = partition.getActiveDoor();

        return door.dimensions.height.getLength().toFt().getValue();
    }

    static getTopFrameWidth(partition: Partition): number {
        // const overdoor = partition.getActiveOverdoor();

        // if (overdoor.isTransom()) {
        //     return this.getTotalWidth(partition);
        // }

        return this.getPartitionWidthWithoutDoor(partition);
    }

    static getBottomFrameWidth(partition: Partition): number {
        // const overdoor = partition.getActiveOverdoor();

        // if (overdoor.isTransom()) {
        //     return this.getTotalWidth(partition);
        // }

        return this.getPartitionWidthWithoutDoor(partition);
    }

    static getPartitionWidthWithoutDoor(partition: Partition): number {
        return (
            this.getTotalWidth(partition) - this.getDoorTotalWidth(partition)
        );
    }

    static getTransomHeight(partition: Partition): number {
        const overdoor = partition.getActiveOverdoor();

        if (overdoor.isTransom()) {
            return overdoor.dimensions.height.getLength().toFt().getValue();
        }

        return 0;
    }

    static getMullionsQty = (partition: Partition): number => {
        const doorWidth = MountsService.getDoorTotalWidth(partition);

        /**
         *  Mullions в данном случае - это:
         *  Направляющие профили, которые монтируются сверху проема
         *  По этим направляющим будут скользить двери
         *  Для раздвижных дверей требуется два параллельных профиля mullion:
         *
         *  Один профиль для передней двери
         *  Второй профиль для задней двери (которая движется параллельно первой)
         *
         *  Таким образом, для одной двери требуется 2 профиля mullion
         *
         */
        const mullionsQty = 2;

        return doorWidth * mullionsQty;
    };

    static getStipJointsQty = (partition: Partition): number => {
        const totalWidth = this.getTotalWidth(partition);
        const doorWidth = this.getDoorTotalWidth(partition);

        const doorSections = 3;

        return Math.round((totalWidth - doorWidth) / doorSections) - 1;
    };

    static getStipJointsWithoutDoorQty = (partition: Partition): number => {
        const totalWidth = this.getTotalWidth(partition);
        const doorWidth = this.getDoorTotalWidth(partition);

        const doorSections = 3;

        return Math.round(totalWidth - doorWidth) / doorSections;
    };

    static getTopTrack = (partition: Partition): string => {
        const dictStore = useDictStore();
        const width = this.getTotalWidth(partition);
        const topTrack = dictStore.findMullionTrack(width);

        console.log("width", width);
        console.log("topTrack", topTrack);

        return topTrack?.code || "";
    };

    static isForDoubleDoor = (partition: Partition): boolean => {
        const door = partition.getActiveDoor();

        return door.isDoubleDoor();
    };

    static hasOverdoorTransom = (partition: Partition): boolean => {
        const overdoor = partition.getActiveOverdoor();

        return overdoor.isTransom();
    };
}
