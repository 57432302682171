import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsToptrackSlidingDoorConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF400Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsToptrackSlidingDoorConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(0)
        .area(1)
        .build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsCGFBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsTopConfig().qty(1).area(qty).build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig().qty(1).area(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const horizontalLineQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF400Code,
            totalHeight,
        );

    const qty = totalWidth * horizontalLineQty * sides;

    return createMountsMullionsHorizontalConfig()
        .qty(horizontalLineQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const partitionWidth = MountsService.getTotalWidth(partition);
    const partitionHeight = MountsService.getTotalHeight(partition);

    const verticalLinesQty =
        LineDividerCalculationService.calculateVerticalDividers(
            CGF400Code,
            partitionWidth,
        );
    const sides = 2;

    const qty = verticalLinesQty * partitionHeight * sides;

    return createMountsMullionsVerticalConfig()
        .qty(verticalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

export const CGF400SlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsToptrackSlidingDoorConfig(partition),
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsMullionsTopConfig(partition),
    createSlidingDoorMountsMullionsBottomConfig(partition),
    createSlidingDoorMountsMullionsHorizontalConfig(partition),
    createSlidingDoorMountsMullionsVerticalConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
