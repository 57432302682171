import {
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";

import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsKompasHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);

    const horizontalLinesQty = 1;
    const sides = 2;

    const qty = width * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig()
        .qty(horizontalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsKompasVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const height = MountsService.getTotalHeight(partition);

    const horizontalLinesQty = 1;
    const sides = 2;

    const qty = height * horizontalLinesQty * sides;

    return createMountsMullionsVerticalConfig()
        .qty(horizontalLinesQty)
        .area(qty)
        .build();
};

export const KompasSwingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsKompasHorizontalConfig(partition),
    createSlidingDoorMountsKompasVerticalConfig(partition),
];
