import { Partition } from "@/app/domain";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsToptrackSlidingDoorConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";

const createSlidingDoorMountsToptrackSlidingDoorConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(0)
        .area(1)
        .build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsCGFBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsTopConfig().qty(1).area(qty).build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig().qty(1).area(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);
    const sides = 2;

    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            height,
        );

    const qty = width * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig()
        .qty(horizontalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);
    const sides = 2;

    const verticalLinesQty =
        LineDividerCalculationService.calculateVerticalDividers(
            CGF350Code,
            width,
        );

    const qty = height * verticalLinesQty * sides;

    return createMountsMullionsVerticalConfig()
        .qty(verticalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const cgfSidesQty = 4;
    const doorMultiplier = MountsService.isForDoubleDoor(partition) ? 4 : 1;

    const qty = totalHeight * cgfSidesQty * doorMultiplier;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

export const CGF350SlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsToptrackSlidingDoorConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsMullionsTopConfig(partition),
    createSlidingDoorMountsMullionsBottomConfig(partition),
    createSlidingDoorMountsMullionsHorizontalConfig(partition),
    createSlidingDoorMountsMullionsVerticalConfig(partition),
    // createSlidingDoorMountsCGFFrameConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
