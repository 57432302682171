import {
    createMounts3MTapeConfig,
    createMountsCGFFrameConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsTopConfig,
    createMountsMullionsVerticalConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsToptrackSlidingDoorConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(1)
        .area(1)
        .build();
};

const createSlidingDoorMountsKompasHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);

    const horizontalLinesQty = 1;
    const sides = 2;

    const qty = width * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig()
        .qty(horizontalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsKompasVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const height = MountsService.getDoorTotalHeight(partition);

    const verticalLinesQty = 1;
    const sides = 2;

    const qty = height * verticalLinesQty * sides;

    return createMountsMullionsVerticalConfig()
        .qty(verticalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsTopConfig().qty(1).area(qty).build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig().qty(1).area(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorMultiplier = MountsService.isForDoubleDoor(partition) ? 2 : 1;
    const sides = 2;

    const qty = totalHeight * sides * doorMultiplier;

    return createMountsCGFFrameConfig().qty(qty).area(qty).build();
};

const createSlidingDoorMounts3MTapeConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorMultiplier = MountsService.isForDoubleDoor(partition) ? 2 : 1;
    const sides = 2;

    const qty = totalHeight * sides * doorMultiplier;

    return createMounts3MTapeConfig().qty(qty).area(qty).build();
};

export const KompasSlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsToptrackSlidingDoorConfig(partition),
    createSlidingDoorMountsKompasHorizontalConfig(partition),
    createSlidingDoorMountsKompasVerticalConfig(partition),
    createSlidingDoorMountsMullionsTopConfig(partition),
    createSlidingDoorMountsMullionsBottomConfig(partition),
    // createSlidingDoorMountsCGFFrameConfig(partition),
    // createSlidingDoorMounts3MTapeConfig(partition),
];
