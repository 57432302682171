import { getFromDictByCode, useDictStore } from "@/stores/dictStore";
import { IComponentConfig } from "@/app/@types/base-component";
import {
    MountCGF1FrameValueCode,
    MountCGF1MullionsValueCode,
    MountGlazingGasket1ValueCode,
    MountKomapsAluminiumProfileValueCode,
    MountRomanoSteelFrameValueCode,
    Mounts3MTapeTypeCode,
    MountsBottomFrameSortTypeCode,
    MountsCGFBottomFrameSortTypeCode,
    MountsCGFFrameTypeCode,
    MountsCGFSideFrameSortTypeCode,
    MountsCGFTopFrameSortTypeCode,
    MountsCGSFrameTypeCode,
    MountsCode,
    MountsGlazingGasketTypeCode,
    MountsKompasHorizontalTypeCode,
    MountsKompasVerticalTypeCode,
    MountsMullionsBottomTypeCode,
    MountsMullionsHorizontalTypeCode,
    MountsMullionsMiddleTypeCode,
    MountsMullionsTopTypeCode,
    MountsMullionsVerticalTypeCode,
    MountsRomanoFrameTypeCode,
    MountsSideFrameSortTypeCode,
    MountsStripesTypeCode,
    MountsTopFrameSortTypeCode,
    MountsToptrackSliding1ValueCode,
    MountsToptrackEndTypeCode,
    MountsToptrackNoholderTypeCode,
    MountsToptrackNoholderValueCode,
    MountsToptrackSlidingDoorTypeCode,
    MountsToptrackTypeCode,
    MountsTreviFrameTypeCode,
    MountStripes1ValueCode,
    MountTape1ValueCode,
    MountTreviSteelFrameValueCode,
    MountUchCGF1ValueCode,
    MountUchDeep1_12ValueCode,
    MountUchLow1_2ValueCode,
} from "@/app/constants/mounts";

import { ReactiveComponentConfig } from "@/app/@types/dimensions";

export type ConfigBuilder = {
    typeCode(value: string): ConfigBuilder;
    structureType(value: string): ConfigBuilder;
    title(): ConfigBuilder;
    qty(value: number): ConfigBuilder;
    area(value: number): ConfigBuilder;
    build(): IComponentConfig;
};

export const createMountsTopFrameSortConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsTopFrameSortTypeCode)
        .structureType(MountUchDeep1_12ValueCode)
        .title();
};

export const createMountsSideFrameSortConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsSideFrameSortTypeCode)
        .structureType(MountUchDeep1_12ValueCode)
        .title();
};

export const createMountsBottomFrameSortConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsBottomFrameSortTypeCode)
        .structureType(MountUchLow1_2ValueCode)
        .title();
};

export const createMountsStripesConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsStripesTypeCode)
        .structureType(MountStripes1ValueCode)
        .title();
};

export const createMounts3MTapeConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(Mounts3MTapeTypeCode)
        .structureType(MountTape1ValueCode)
        .title();
};

export const createMountsMullionsTopConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsMullionsTopTypeCode)
        .structureType(MountCGF1MullionsValueCode)
        .title();
};

export const createMountsMullionsBottomConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsMullionsBottomTypeCode)
        .structureType(MountCGF1MullionsValueCode)
        .title();
};

export const createMountsMullionsMiddleConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsMullionsMiddleTypeCode)
        .structureType(MountCGF1MullionsValueCode)
        .title();
};

export const createMountsMullionsHorizontalConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsMullionsHorizontalTypeCode)
        .structureType(MountCGF1MullionsValueCode)
        .title();
};

export const createMountsMullionsVerticalConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsMullionsVerticalTypeCode)
        .structureType(MountCGF1MullionsValueCode)
        .title();
};

export const createMountsToptrackConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsToptrackTypeCode)
        .structureType(MountsToptrackSliding1ValueCode)
        .title();
};

export const createMountsToptrackSlidingDoorConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsToptrackSlidingDoorTypeCode)
        .structureType(MountsToptrackSliding1ValueCode)
        .title();
};

export const createMountsCGFFrameConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsCGFFrameTypeCode)
        .structureType(MountCGF1FrameValueCode)
        .title();
};

export const createMountsToptrackEndConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsToptrackEndTypeCode)
        .structureType(MountsToptrackNoholderValueCode)
        .title();
};

export const createMountsGlazingGasketConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsGlazingGasketTypeCode)
        .structureType(MountGlazingGasket1ValueCode)
        .title();
};

export const createMountsKompasHorizontalConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsKompasHorizontalTypeCode)
        .structureType(MountKomapsAluminiumProfileValueCode)
        .title();
};

export const createMountsKompasVerticalConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsKompasVerticalTypeCode)
        .structureType(MountKomapsAluminiumProfileValueCode)
        .title();
};

export const createMountsTreviFrameConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsTreviFrameTypeCode)
        .structureType(MountTreviSteelFrameValueCode)
        .title();
};

export const createMountsRomanoFrameConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsRomanoFrameTypeCode)
        .structureType(MountRomanoSteelFrameValueCode)
        .title();
};

export const createMountsCGSFrameConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsCGSFrameTypeCode)
        .structureType(MountsToptrackNoholderValueCode)
        .title();
};

export const createMountsCGFTopFrameSortConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsCGFTopFrameSortTypeCode)
        .structureType(MountUchCGF1ValueCode)
        .title();
};

export const createMountsCGFSideFrameSortConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsCGFSideFrameSortTypeCode)
        .structureType(MountUchCGF1ValueCode)
        .title();
};

export const createMountsCGFBottomFrameSortConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsCGFBottomFrameSortTypeCode)
        .structureType(MountUchCGF1ValueCode)
        .title();
};

export const createMountsToptrackNoholderConfig = (): ConfigBuilder => {
    return createMountsHadwareConfig()
        .typeCode(MountsToptrackNoholderTypeCode)
        .structureType(MountsToptrackNoholderValueCode)
        .title();
};

export const createMountsHadwareConfig = (): ConfigBuilder => {
    const config = {
        title: "",
        structureType: "",
        typeCode: "",
        qty: 1,
        dimensions: {} as ReactiveComponentConfig,
    };

    const builder = {
        typeCode(value: string) {
            config.typeCode = value;
            return builder;
        },
        structureType(value: string) {
            config.structureType = value;
            return builder;
        },
        qty(value: number) {
            // const normalizedQty =
            //     value % 1 === 0 ? value.toFixed(0) : value.toFixed(1);
            config.qty = value;
            return builder;
        },
        title() {
            const title = getHardwareItemTitle(config.typeCode);
            config.title = title;
            return builder;
        },
        area(value: number) {
            const dimensions: ReactiveComponentConfig = {
                width: {
                    ft: 0,
                    inch: 0,
                    fractionDividend: 0,
                    fractionDivisor: 0,
                },
                height: {
                    ft: 0,
                    inch: 0,
                    fractionDividend: 0,
                    fractionDivisor: 0,
                },
                area: value,
            };

            config.dimensions = dimensions;

            return builder;
        },
        build() {
            return config;
        },
    };

    return builder;
};

const getHardwareItemTitle = (hardwareTypeCode: string): string => {
    const dictStore = useDictStore();

    const title = getFromDictByCode(
        dictStore.getHardwareTypes(MountsCode),
        hardwareTypeCode,
    )?.title;

    // Replace title for mounts CGF systems
    return title.replace("CGF ", "");
};
